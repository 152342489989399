<template>
  <div class="trust">
    <div class="topPart">
      <div class="form-item">
        <div class="inputBox" style="margin-right: 10px">
          <div class="title">企业名称</div>
          <Input
            v-model="params.qyjc"
            placeholder="模糊搜索"
            style="margin-right: 50px; width: 300px"
          />
        </div>
        <div class="inputBox" style="margin-right: 10px">
          <div class="title">股票代码</div>
          <Input
            v-model="params.gpdm"
            placeholder="模糊搜索"
            style="margin-right: 50px; width: 300px"
          />
        </div>
      </div>
      <div class="form-item">
        <span class="title">公告时间</span>
        <DatePicker
          type="date"
          @on-change="getTimeStart"
          v-model="params.ggrq[0]"
          format="yyyy-MM-dd"
          placeholder="开始时间"
          style="width: 140px; margin-right: 5px"
        ></DatePicker
        >至
        <DatePicker
          type="date"
          v-model="params.ggrq[1]"
          @on-change="getTimeEnd"
          format="yyyy-MM-dd"
          placeholder="结束时间"
          style="width: 140px; margin: 0 50px 0 5px"
        ></DatePicker>
        <!-- <span class="title">融资日期</span>
        <DatePicker type="date"
                    format="yyyy-MM-dd"
                    @on-change="getTimeStartrz"
                    v-model="params.sjrzrq[0]"
                    placeholder="开始时间"
                    style="width: 140px; margin-right:5px;"></DatePicker>至
        <DatePicker type="date"
                    format="yyyy-MM-dd"
                    v-model="params.sjrzrq[1]"
                    @on-change="getTimeEndrz"
                    placeholder="结束时间"
                style="width: 140px;margin:0 50px 0 5px;"></DatePicker>-->
        <div class="inputBox" style="margin-right: 60px">
          <div class="title">融资类型</div>
          <Select clearable v-model="params.rzfszdh[0]" style="width: 300px">
            <Option
              v-for="item in zqlxzdhList"
              :value="item.name"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </div>
      </div>
      <!--<div class="form-item">

        &lt;!&ndash;<div class="inputBox"
             style="margin-right:60px;">
          <div class="title">融资方式</div>
          <Select v-model="params.rzfs[0]"
                  style="width:300px;">
            <Option v-for="item in zqlxList"
                    :value="item.name"
                    :key="item.id">{{ item.name }}</Option>
          </Select>
        </div>&ndash;&gt;
            </div>-->
      <Button
        class="searchBtnLog" data-id="1"
        type="info"
        style="width: 144px; display: block; margin: 0 auto"
        @click="getList"
        >查询</Button
      >
    </div>
    <div class="middlePart">
      <span>已选条件：</span>
      <Tag
        type="border"
        v-if="params.qyjc"
        size="large"
        @on-close="params.qyjc = ''"
        closable
      >
        企业名称:
        <span class="tabContent">{{ params.qyjc }}</span>
      </Tag>
      <Tag
        type="border"
        v-if="params.gpdm"
        size="large"
        @on-close="params.gpdm = ''"
        closable
      >
        股票代码:
        <span class="tabContent">{{ params.gpdm }}</span>
      </Tag>
      <Tag
        type="border"
        v-if="params.rzfszdh[0]"
        size="large"
        @on-close="params.rzfszdh = ['']"
        closable
      >
        融资类型:
        <span class="tabContent">{{ params.rzfszdh[0] }}</span>
      </Tag>
      <Tag
        type="border"
        v-if="params.rzfs[0]"
        size="large"
        @on-close="params.rzfs = ['']"
        closable
      >
        融资方式:
        <span class="tabContent">{{ params.rzfs[0] }}</span>
      </Tag>
      <Tag
        type="border"
        v-if="params.ggrq[0] && params.ggrq[1]"
        size="large"
        @on-close="params.ggrq = []"
        closable
      >
        公告时间:
        <span class="tabContent"
          >{{ params.ggrq[0] }}至{{ params.ggrq[1] }}</span
        >
      </Tag>
      <Tag
        type="border"
        v-if="params.sjrzrq[0] && params.sjrzrq[1]"
        size="large"
        @on-close="params.sjrzrq = []"
        closable
      >
        融资日期:
        <span class="tabContent"
          >{{ params.sjrzrq[0] }}至{{ params.sjrzrq[1] }}</span
        >
      </Tag>
    </div>
    <div class="bottomPart">
      <DownloadBtn
        :id="4"
        :type=1
        @exportData="exportData"
        :style="{
          position: 'relative',
          left: 0,
          'margin-bottom': '10px',
          'background-color': 'rgba(241, 110, 113, 1)',
        }"
      ></DownloadBtn>
      <Table
        border
        tooltip-theme="light"
        :columns="stockColumns"
        :loading="tableLoading"
        :data="stockTable"
      >
        <template slot-scope="{ row }" slot="action">
          <Button
            type="primary"
            size="small"
            style="margin-right: 5px"
            @click.stop="tableClick(row)"
            >详情</Button
          >
        </template>
      </Table>
      <div style="text-align: right; padding: 20px">
        <Page :total="total" show-elevator @on-change="pageChange" />
      </div>
    </div>
  </div>
</template>

<script>
import DownloadBtn from "@/components/DownloadBtn";
import { mapState, mapMutations } from "vuex";
import {
  getOtherData,
  getDetail,
  qtrz,
  getQtrzfs,
} from "@/api/financial/other";
import { downloadData, formatJson } from "@/utils/index";
import { zqlxzdh, zqlx } from "@/api/financial/debt";
import dataReports from "@/mixins/dataReports";

export default {
  name: "trust",
  components: {
    DownloadBtn,
  },
  mixins: [dataReports],
  data() {
    return {
      params: {
        current: 0,
        rzfszdh: [""],
        ggrq: [""],
        gpdm: "",
        qyjc: "",
        qyqc: "",
        rzfs: [""],
        size: 10,
        sjrzrq: [""],
      },
      total: 0,
      stockTable: [],
      tableLoading: false,
      zqlxList: [],
      zqlxzdhList: [],
      stockColumns: [
        {
          title: "融资类型",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "rzfszdh",
        },
        {
          title: "股票代码",
          align: "center",
          ellipsis: true,
          width: 100,
          tooltip: true,

          key: "gpdm",
        },
        {
          title: "企业简称",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "qyjc",
        },
        {
          title: "公告日期",
          ellipsis: true,
          tooltip: true,

          align: "center",
          width: 120,
          key: "ggrq",
        },
        // {
        //   title: '实际融资日期',
        //   ellipsis: true,
        //   "width": 150,
        //
        //   align: 'center',
        //   key: 'sjrzrq'
        // },
        // {
        //   title: '融资状态',
        //   ellipsis: true,
        //   "width": 150,
        //
        //   align: 'center',
        //   key: 'rzzt'
        // },
        {
          title: "融资方式",
          ellipsis: true,
          width: 150,
          tooltip: true,

          align: "center",
          key: "rzfs",
        },
        {
          title: "融资规模 （亿元）",
          ellipsis: true,
          width: 100,
          tooltip: true,

          align: "center",
          renderHeader: (h, index) => {
            return h("div", [h("div", "融资规模"), h("div", "（亿元）")]);
          },
          key: "rzgmyy",
          render: (h, params) => {
            return h(
              "span",
              params.row.rzgmyy
                ? isNaN(params.row.rzgmyy)
                  ? params.row.rzgmyy
                  : Number(params.row.rzgmyy).toFixed(2)
                : params.row.rzgmyy
            );
          },
        },
        {
          title: "币种",
          align: "center",
          width: 100,
          ellipsis: true,
          tooltip: true,

          key: "bz",
        },
        {
          title: "融资利率",
          ellipsis: true,
          width: 150,
          tooltip: true,

          align: "center",
          key: "rzll",
          render: (h, params) => {
            return h(
              "span",
              params.row.rzll
                ? isNaN(params.row.rzll)
                  ? params.row.rzll
                  : Number(params.row.rzll).toFixed(2)
                : params.row.rzll
            );
          },
        },
        {
          title: "期限",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "qx",
        },
        {
          title: "实际融资人",
          ellipsis: true,
          tooltip: true,

          width: 130,
          align: "center",
          key: "sjrzr",
        },
        {
          title: "股权比例",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "gqbl",
           render: (h, params) => {
            return h(
              "span",
              params.row.gqbl
                ? isNaN(params.row.gqbl)
                  ? params.row.gqbl
                  : Number(params.row.gqbl * 100).toFixed(2) + "%"
                : params.row.gqbl
            );
          },
        },
        {
          title: "融资详情",
          ellipsis: true,
          tooltip: true,

          width: 150,
          align: "center",
          slot: "action",
          key: "rzxq",
        },
      ],
    };
  },
  beforeMount() {
    this.$Notice.destroy();
  },
  mounted() {
    this.init();
    this.getList();
    this._zqlxzdh();

  },
  computed: {
    ...mapState(["activeDetail"]),
  },
  methods: {
    ...mapMutations(["changeState"]),
    getTimeEnd(value) {
      this.$set(this.params.ggrq, 1, value);
    },
    getTimeStart(value) {
      this.$set(this.params.ggrq, 0, value);
    },
    getTimeEndrz(value) {
      this.$set(this.params.sjrzrq, 1, value);
    },
    getTimeStartrz(value) {
      this.$set(this.params.sjrzrq, 0, value);
    },
    getzqlx(data) {
      if (!data) return;
      zqlx({
        pid: data,
      }).then((res) => {
        this.zqlxList = res;
        this.params.rzfs = [""];
      });
    },
    _zqlxzdh() {
      getQtrzfs({ lx: 1 }).then((data) => {
        this.zqlxzdhList = data;
      });
      getQtrzfs({ lx: 2 }).then((data) => {
        this.zqlxList = data;
      });
    },
    tableClick(row) {
      getDetail({
        id: row.id,
      }).then((info) => {
        this.changeState({
          prop: "activeDetail",
          value: info,
        });
        localStorage.setItem("activeDetail", JSON.stringify(info));
        // this.$router.push({
        //     path: `/Insight/financialMarket/financialProduct/otherDetail`
        // });
        let routeData = this.$router.resolve({
          path: `/Insight/financialMarket/financialProduct/others/otherDetail`,
        });
        window.open(routeData.href, "_blank");
      });
    },
    getList() {
      this.tableLoading = true;
      this.params.ggrq = this.params.ggrq.filter((item) => item);
      this.params.sjrzrq = this.params.sjrzrq.filter((item) => item);
      this.params.rzfs = this.params.rzfs.filter((item) => item);
      this.params.rzfszdh[0] == 10086 && this.params.rzfszdh.splice(0, 1);
      // this.params.fl = this.params.fl.filter(item => item !== 10086)
      this.params.rzfszdh = this.params.rzfszdh.filter((item) => item);

      getOtherData(this.params)
        .then((data) => {
          this.stockTable = data.records;
          this.total = data.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    pageChange(size) {
      this.params.current = size;
      this.getList();
    },
    exportData() {
      this.params.ggrq = this.params.ggrq.filter((item) => item);
      this.params.sjrzrq = this.params.sjrzrq.filter((item) => item);
      this.params.rzfs = this.params.rzfs.filter((item) => item);
      this.params.rzfszdh[0] == 10086 && this.params.rzfszdh.splice(0, 1);
      // this.params.fl = this.params.fl.filter(item => item !== 10086)
      this.params.rzfszdh = this.params.rzfszdh.filter((item) => item);
      this.params.size = 10000000;
      this.params.current = 1;

      getOtherData(this.params).then((data) => {
        const tableData = data.records;
        let filterVal = [];
        let tHeader = [];
        this.stockColumns.forEach((item) => {
          tHeader.push(item.title);
          filterVal.push(item.key);
        });
        const resData = formatJson(filterVal, tableData);
        downloadData(tHeader, resData, "其他融资");
      });
    },
    init() {
      qtrz({ lx: 1 }).then((res) => {
        // this.zdhList = [{id: 10086,name:'全部'},...res]
        this.zdhList = [...res];
      });
      qtrz({ lx: 2 }).then((res) => {
        this.ysList = res;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.trust {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
}
.trust {
  .topPart {
    width: 100%;
    height: 200px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    border: 1px solid rgba(227, 227, 229, 1);
    padding: 20px 30px;
    .form-item {
      margin-bottom: 20px;
      @include flex(flex-start);
      .inputBox {
        @include flex(flex-start);
      }
      .title {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: rgba(102, 102, 102, 1);
        line-height: 19px;
        margin-right: 20px;
        word-break: keep-all;
      }
      .addIssue {
        position: relative;
        .seletcTypeBox {
          position: absolute;
          left: 0;
          top: 20px;
          height: 40px;
          display: none;
          overflow: hidden;
          background: #fff;
          border: 1px solid rgba(227, 227, 229, 1);
          padding: 10px;
          z-index: 100;
        }
        &:hover .seletcTypeBox {
          display: block;
        }
      }
    }
  }
  .middlePart {
    margin: 20px 0;
  }
}
</style>
